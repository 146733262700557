import { render, staticRenderFns } from "./CtaSection.vue?vue&type=template&id=4c33a061&scoped=true&"
import script from "./CtaSection.vue?vue&type=script&lang=js&"
export * from "./CtaSection.vue?vue&type=script&lang=js&"
import style0 from "./CtaSection.vue?vue&type=style&index=0&id=4c33a061&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c33a061",
  null
  
)

export default component.exports